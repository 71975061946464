var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-6"},[_c('v-card',{staticClass:"elevation-4 mb-13"},[_c('v-row',{staticClass:"px-5"},[_c('v-col',{staticClass:"pb-0"},[_c('v-autocomplete',{staticStyle:{"max-width":"250px"},attrs:{"items":_vm.allEmails,"label":"Select the client","item-text":"to","item-value":"to"},on:{"change":_vm.searchEmailsByEmails},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.to))])]}}]),model:{value:(_vm.filterEmail.to),callback:function ($$v) {_vm.$set(_vm.filterEmail, "to", $$v)},expression:"filterEmail.to"}})],1)],1),_c('v-card-text',[_c('v-card',{staticClass:"table-container",attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"footer-props":{
          'items-per-page-options': _vm.itemsPerPageOptions
        },"headers":_vm.headers,"items":_vm.emails,"loading":_vm.loading,"loading-text":"Loading... Please wait","items-per-page":_vm.pagination.size,"server-items-length":_vm.totalItems,"page":_vm.pagination.number,"sort-by":_vm.sort.sortBy,"sort-desc":_vm.sort.sortDesc,"dense":""},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "size", $event)},"update:items-per-page":[function($event){return _vm.$set(_vm.pagination, "size", $event)},function($event){return _vm.loadData()}],"update:page":[function($event){return _vm.$set(_vm.pagination, "number", $event)},function($event){return _vm.changePage()}],"update:sortBy":function($event){return _vm.$set(_vm.sort, "sortBy", $event)},"update:sort-by":[function($event){return _vm.$set(_vm.sort, "sortBy", $event)},function($event){return _vm.debouncedLoadData(100)}],"update:sortDesc":function($event){return _vm.$set(_vm.sort, "sortDesc", $event)},"update:sort-desc":[function($event){return _vm.$set(_vm.sort, "sortDesc", $event)},function($event){return _vm.debouncedLoadData(100)}]},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(_vm.formatDate(item.created_at)))]}},{key:"item.to",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{staticClass:"text-normalize",staticStyle:{"font-weight":"400"},attrs:{"size":"small","color":"teal","text":""},on:{"click":function($event){return _vm.toDashboard(item)}}},[_vm._v(" "+_vm._s(item.to)+" ")])]}},{key:"item.subject",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(item.subject))]}},{key:"item.body",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{staticClass:"text-normalize",attrs:{"size":"small","color":"teal","text":""},on:{"click":function($event){return _vm.openDialog(item)}}},[_vm._v("View Email")])]}}])},[_vm._l((_vm.headers),function(item,index){return [_c('span',{key:index},[_vm._v(_vm._s(item.text))])]})],2)],1)],1)],1),_c('v-dialog',{staticClass:"elevation-0",model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"height":"100vh"}},[_c('iframe',{ref:"previewFrame",staticStyle:{"background-color":"#f2f2f2","border":"0!important"},attrs:{"width":"100%","height":"100%","srcdoc":_vm.iframeUrl,"frameborder":"0"}})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }