
import Vue from "vue";
import API from "@/api/API";
import index from "vuex";
import store from "@/store";
import {parseArrayParams} from "@/services/helpers";
import tablePagination from "@/mixins/table-pagination";
import tableFilters from "@/mixins/table-filters";
import {Dictionary} from "vue-router/types/router";
export default Vue.extend({
    mixins: [tablePagination, tableFilters],

    name: "Emails",
    computed: {
        index() {
            return index
        },
    },
    data: () => ({
        parseArrayParams,
        filter: {} as Dictionary<string | (string | null)[]>,
        headers: [
            {
                text: "Send",
                value: "created_at",
                sortable: false
            },
            {
                text: "To",
                value: "to",
                sortable: false
            },
            {
                text: "Subject",
                value: "subject",
                sortable: false
            },
            {
                text: "Body",
                value: "body",
                sortable: false
            }
        ],
        emails: [] as Array<any>,
        totalItems: 0 as number,
        loading: false as boolean,
        dialog: false as boolean,
        iframeUrl: false as any,
        isLoadingEmails: true as boolean,
        isLoadingEmailContent: false as boolean,
        filterEmail: {
            to: ""
        },
        allEmails: [] as Array<any>,
    }),
    created() {
        this.loadAllEmails();
    },
    async beforeRouteEnter(to, from, next) {
        try {
            const params = {
                filter: {
                    to: "All",
                },
                page: {
                    number: to.params.page,
                    size: 20
                }
            };
            const response = await API.archive().list(params);
            next(vm => {
                vm.setServerResponse(response);
            });
        } catch (e) {
            await store.dispatch("alert/showError", e.message);
        }
    },
    methods: {
        getSortParams(): string | undefined {
            if (this.sort.sortBy.length) {
                const [sortBy]: Array<string> = this.sort.sortBy;
                const [sortDesc]: Array<string> = this.sort.sortDesc;
                return `${sortDesc ? "" : "-"}${sortBy}`;
            }
            return undefined;
        },
        setServerResponse({items}: any) {
            this.emails = items.data;
            this.totalItems = items.total;
        },
        async loadData(): Promise<void> {
            this.loading = true;
            try {
                const response = await this.$API.archive().list({
                    filter: this.filterEmail,
                    page: this.pagination,
                });
                this.setServerResponse(response);
            } catch (e) {
                await this.$store.dispatch("alert/showError", e.message);
            }
            this.loading = false;
        },
        async loadAllEmails(){
            try {
                const response = await this.$API.archive().getAllEmails();
                if(response){
                    const newEmail = {
                        to: "All"
                    };
                    this.allEmails = [newEmail, ...response.content];
                }
            } catch (e){
                await this.$store.dispatch("alert/showError", e.message);
            }
        },
        async openDialog(item: any) {
            try {
                this.loading = true
                const response = await this.$API.archive().getBodyFromEmail(item.id);
                if(response){
                    this.dialog = true;
                    this.iframeUrl = response.content.body;
                }
                this.loading = false
            } catch (e) {
                await this.$store.dispatch("alert/showError", e.message);
                this.loading = false
            }
        },
        formatDate(inputDate: string | number | Date){
            const dateObject = new Date(inputDate);
            return dateObject.toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
            });
        },
        searchEmailsByEmails(){
            this.loadData();
        },
        async toDashboard(item: any){
            await this.$router.push(`/profile/${item.user_id}/dashboard`);
        }
    }
});
